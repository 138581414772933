<template>
  <v-container>
    <h2>Planner Layout Settings</h2>
    <v-row v-for="(prop, index) in plannerProperties" :key="index">
      <v-col v-if="prop.key === 'closeProgramHeader'">
        <h4>{{ translations.closeProgramHeader }}</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque cumque dolores, ea est expedita fugiat iusto molestiae molestias neque officia officiis optio possimus, quas quo repellendus sapiente ullam, veniam voluptatibus.</p>
        <v-btn-toggle>
          <v-btn :disabled="prop.value === 'true'" @click="updateValue('enable', index)">Enable</v-btn>
          <v-btn :disabled="prop.value === 'false'" @click="updateValue('disable', index)">Disable</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col v-if="prop.key === 'fixedProjectScheduleHeight'">
        <h4>{{ translations.fixedProjectScheduleHeight }}</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque cumque dolores, ea est expedita fugiat iusto molestiae molestias neque officia officiis optio possimus, quas quo repellendus sapiente ullam, veniam voluptatibus.</p>
        <v-btn-toggle>
          <v-btn :disabled="prop.value === 'true'" @click="updateValue('enable', index)">Enable</v-btn>
          <v-btn :disabled="prop.value === 'false'" @click="updateValue('disable', index)">Disable</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col v-if="prop.key === 'plannerResourcesSelectedOnBottom'">
        <h4>{{ translations.plannerResourcesSelectedOnBottom }}</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque cumque dolores, ea est expedita fugiat iusto molestiae molestias neque officia officiis optio possimus, quas quo repellendus sapiente ullam, veniam voluptatibus.</p>
        <v-btn-toggle>
          <v-btn :disabled="prop.value === 'true'" @click="updateValue('enable', index)">Enable</v-btn>
          <v-btn :disabled="prop.value === 'false'" @click="updateValue('disable', index)">Disable</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col v-if="prop.key === 'showProjectDescriptionInSchedule'">
        <h4>{{ translations.showProjectDescriptionInSchedule }}</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque cumque dolores, ea est expedita fugiat iusto molestiae molestias neque officia officiis optio possimus, quas quo repellendus sapiente ullam, veniam voluptatibus.</p>
        <v-btn-toggle>
          <v-btn :disabled="prop.value === 'true'" @click="updateValue('enable', index)">Enable</v-btn>
          <v-btn :disabled="prop.value === 'false'" @click="updateValue('disable', index)">Disable</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col v-if="prop.key === 'useBiggerFontInSchedule'">
        <h4>{{ translations.useBiggerFontInSchedule }}</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque cumque dolores, ea est expedita fugiat iusto molestiae molestias neque officia officiis optio possimus, quas quo repellendus sapiente ullam, veniam voluptatibus.</p>
        <v-btn-toggle>
          <v-btn :disabled="prop.value === 'true'" @click="updateValue('enable', index)">Enable</v-btn>
          <v-btn :disabled="prop.value === 'false'" @click="updateValue('disable', index)">Disable</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-btn @click.prevent="saveSettings" color="main" class="white--text px-8 mt-8">Save</v-btn>
  </v-container>
</template>


<script>
import { translations } from '@/utils/common'
import PropertyService from '@/service/property/PropertyService'

export default {
  props: ['plannerLayoutProperties'],

  data() {
    return {
      plannerProperties: [],
      selectedValue: ''
    }
  },

  computed: {
    translations() {
      return translations
    }
  },

  mounted() {
    this.plannerProperties = this.plannerLayoutProperties
  },

  methods: {
    async saveSettings() {
      const propertiesDTO = this.plannerProperties.map(({ key, value, type }) => ({
        key,
        value,
        type
      }))
      await PropertyService.saveList({ propertiesDTO: propertiesDTO })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
    },

    updateValue(status, index) {
      this.selectedValue = status === 'enable'
      this.plannerProperties[index].value = this.selectedValue === true
    }
  }
}
</script>


<style scoped>
h2 {
  margin-bottom: 15px;
}
</style>
