<template>
  <v-container>
    <h2>Codes Settings</h2>
    <v-row v-for="(prop, index) in codes" :key="index">
      <v-col v-if="prop.key === 'maintenanceKeyCode'">
        <h4>Maintenance Key Code</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque cumque dolores, ea est expedita fugiat iusto molestiae molestias neque officia officiis optio possimus, quas quo repellendus sapiente ullam, veniam voluptatibus.</p>
        <v-text-field v-model="prop.value"></v-text-field>
      </v-col>
      <v-col v-if="prop.key === 'layOffEmployeeStatusCode'">
        <h4>Layoff Employee Status Code</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque cumque dolores, ea est expedita fugiat iusto molestiae molestias neque officia officiis optio possimus, quas quo repellendus sapiente ullam, veniam voluptatibus.</p>
        <v-text-field v-model="prop.value"></v-text-field>
      </v-col>
      <v-col v-if="prop.key === 'millingSubpartKeyCode'">
        <h4>Milling Subpart Key Code</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque cumque dolores, ea est expedita fugiat iusto molestiae molestias neque officia officiis optio possimus, quas quo repellendus sapiente ullam, veniam voluptatibus.</p>
        <v-text-field v-model="prop.value"></v-text-field>
      </v-col>
      <v-col v-if="prop.key === 'preparationSubpartKeyCode'">
        <h4>Preparation Subpart Key Code</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque cumque dolores, ea est expedita fugiat iusto molestiae molestias neque officia officiis optio possimus, quas quo repellendus sapiente ullam, veniam voluptatibus.</p>
        <v-text-field v-model="prop.value"></v-text-field>
      </v-col>
    </v-row>
    <v-btn @click.prevent="saveSettings" color="main" class="white--text px-8 mt-8">Save</v-btn>
  </v-container>
</template>


<script>
import PropertyService from '@/service/property/PropertyService'
import { translations } from '@/utils/common'

export default {
  props: ['codesProperties'],

  data() {
    return {
      codes: []
    }
  },

  mounted() {
    this.codes = this.codesProperties
  },

  methods: {
    async saveSettings() {
      this.codes.forEach(prop => {
        if (prop.value === '') {
          prop.value = null
        }
      })
      const propertiesDTO = this.codes.map(({ key, value, type }) => ({
        key,
        value,
        type
      }))
      await PropertyService.saveList({ propertiesDTO: propertiesDTO })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
    }
  }
}
</script>


<style scoped>
h2 {
  margin-bottom: 15px;
}
</style>
