import apolloUtil from '@/service/ApolloUtil'

import manufacturerListQuery from '@/service/manufacturer/ManufacturerListQuery.gql'
import saveManufacturerMutation from '@/service/manufacturer/SaveManufacturerMutation.gql'
import deleteManufacturerMutation from '@/service/manufacturer/DeleteManufacturerMutation.gql'

class ManufacturerService {

  search(params) {
    return apolloUtil.query(manufacturerListQuery, params)
      .then((response) => response?.data?.manufacturerList)
  }

  save(params) {
    return apolloUtil.mutate(saveManufacturerMutation, params)
      .then((response) => response?.data?.saveManufacturer)
  }

  delete(params) {
    return apolloUtil.mutate(deleteManufacturerMutation, params)
  }

}

export default new ManufacturerService()
