<template>
  <div class="recalculate-content d-flex justify-space-around">
    <recalculate-card icon="mdi-timetable" title="Reports" descr="Recalculate report data"
                      :search-function="updateAllEmployeeDailyReports" with-dates />

    <recalculate-card icon="mdi-calendar-text" title="Timelines" descr="Recalculate timelines"
                      :search-function="recalculateWarnings" />

    <recalculate-card icon="mdi-image-filter-hdr-outline" title="Undo certification" descr="Recalculate certificates"
                      :search-function="undoCertification" />
  </div>
</template>


<script>
import RecalculateService from '@/service/recalculate/RecalculateService'

import RecalculateCard from '@/views/Recalculate/RecalculateCard'

export default {
  name: 'Recalculate',

  components: {
    RecalculateCard
  },

  methods: {
    updateAllEmployeeDailyReports(params) {
      return RecalculateService.updateAllEmployeeDailyReports(params)
    },

    recalculateWarnings(params) {
      return RecalculateService.recalculateWarnings(params)
    },

    undoCertification(params) {
      return RecalculateService.undoCertification(params)
    }
  }
}
</script>


<style>
.recalculate-content {
  height: calc(100vh
  - 20px/* margin-top */
  - 10px/* margin-top */
  - 50px/* tab header */
  - 0px/* table footer */
  - 20px /* margin-bottom */
  );
}
</style>
