import apolloUtil from '@/service/ApolloUtil'

import recalculateWarningsMutation from '@/service/recalculate/RecalculateWarningsMutation.gql'
import updateAllEmployeeDailyReportsMutation from '@/service/recalculate/UpdateAllEmployeeDailyReportsMutation.gql'
import undoCertificationMutation from '@/service/recalculate/UndoCertificationMutation.gql'

class RecalculateService {

  recalculateWarnings() {
    return apolloUtil.mutate(recalculateWarningsMutation)
  }

  updateAllEmployeeDailyReports(params) {
    return apolloUtil.mutate(updateAllEmployeeDailyReportsMutation, params)
  }

  undoCertification() {
    return apolloUtil.mutate(undoCertificationMutation)
  }

}

export default new RecalculateService()
