<template>
  <v-list class="list-container">
    <p v-if="item === 1">{{ translations.van }}</p>
    <p v-if="item === 2">{{ translations.refuel }}</p>
    <p v-if="item === 3">{{ translations.milling }}</p>
    <div v-if="item === 4" style="display: flex">
      <p>{{ translations.lunchBreak }}</p>
      <v-icon @click="$emit('toggleLunchTime')" small class="cursor-pointer ml-1">mdi-cog</v-icon>
    </div>
    <p v-if="item === 5">{{ translations.dpiVerification }}</p>
    <p v-if="item === 6">{{ translations.materialUsed }}</p>
    <p v-if="item === 7">{{ translations.asphaltTemperature }}</p>
    <p v-if="item === 8">{{ translations.environmentTemperature }}</p>
    <p v-if="item === 9">{{ translations.manHolesVerification }}</p>
    <p v-if="item === 10">{{ translations.usedEquipmentInShift }}</p>
    <div v-if="item === 11" style="display: flex">
      <p>{{ translations.security }}</p>
      <v-icon @click="$emit('toggleSecurityType')" small class="cursor-pointer ml-1">mdi-cog</v-icon>
    </div>
    <p v-if="item === 12">{{ translations.sameDestinationQuestion }}</p>
    <p v-if="item === 13">{{ translations.equipment }}</p>
    <p v-if="item === 14">{{ translations.noteQuestion }}</p>
    <p v-if="item === 15">{{ translations.boots }}</p>
    <p v-if="item === 16">{{ translations.demolitionMan }}</p>
    <p v-if="item === 17">{{ translations.confinedSpaces }}</p>
    <p v-if="item === 18">{{ translations.deepExcavations }}</p>
    <p v-if="item === 19">{{ translations.driving }}</p>
    <p v-if="item === 20">{{ translations.preparation }}</p>
    <p v-if="item === 21">{{ translations.equipmentQuestion }}</p>
    <p v-if="item === 22">{{ translations.didDpi }}</p>
    <v-icon @click="$emit('deleteQuestion', item)" small color="error lighten-1" class="cursor-pointer ml-1">
      mdi-delete-outline
    </v-icon>
  </v-list>
</template>


<script>
import { translations } from '../../utils/common'

export default {
  props: ['item'],

  computed: {
    translations() {
      return translations
    }
  }
}
</script>


<style scoped>
.list-container {
  display: flex;
  padding: 0;
  width: 100%;
}

p {
  margin-bottom: 0;
}
</style>
