<template>
  <v-container>
    <v-row class="cols-container">
      <!-- column showing key - value properties-->
      <v-col class="key-value-container" :cols="8">

        <v-row v-for="(value, key, index) in questions" :key="index" class="container font-weight-bold" style="border-bottom: 1px solid #b0b0b0">
          <!-- Column displaying only keys -->
          <v-col class="key-container ">
            <p v-if="key === 'operator'">Operator</p>
            <p v-if="key === 'operatorForeman'">Operator Foreman</p>
            <p v-if="key === 'driver'">Driver</p>
            <p v-if="key === 'transfer'">Transfer</p>
          </v-col>
          <!-- Show column values only if the key is not 'transfer' -->
          <v-col v-if="key !== 'transfer'" class="value-container droppable-section" @drop="onDrop" @dragover.prevent :data-section="key">
            <questions-item
              v-for="(val, index) in value"
              :key="index"
              :item="val"
              @toggleLunchTime="toggleLunchTime"
              @toggleSecurityType="toggleSecurityType"
              @deleteQuestion="deleteQuestion(val, key)">
            </questions-item>
          </v-col>

          <!-- Mapping for 'transfer' questions-->
          <v-col v-else class="value-container">
            <!-- project questions -->
            <div class="droppable-section" @drop="onDrop" @dragover.prevent :data-section="'transfer-project'">
              <h5>Project:</h5>
              <questions-item
                v-for="(item, index) in value['project']"
                :key="index"
                :item="item"
                @toggleLunchTime="toggleLunchTime"
                @toggleSecurityType="toggleSecurityType"
                @deleteQuestion="deleteQuestion(item, 'transfer-project')">
              </questions-item>
            </div>
            <v-divider class="mt-2 mb-2" style="width: 100%"></v-divider>
            <!-- end-day questions -->
            <div class="droppable-section" @drop="onDrop" @dragover.prevent :data-section="'transfer-endDay'">
              <h5>End Day:</h5>
              <questions-item
                v-for="(item, index) in value['end-day']"
                :key="index"
                :item="item"
                @toggleLunchTime="toggleLunchTime"
                @toggleSecurityType="toggleSecurityType"
                @deleteQuestion="deleteQuestion(item, 'transfer-endDay')">
              </questions-item>
            </div>
          </v-col>
        </v-row>
        <!-- update lunch time options-->
        <v-row v-if="showLunchTimeOptions" class="container">
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex">
              <div v-for="(option, index) in lunchTimeProperties" :key="index" class="mr-4">
                <v-checkbox v-model="selectedLunchTimeOptions" :label="translations[option.label]" :value="option"></v-checkbox>
              </div>
            </div>
            <div style="display: flex; justify-content: flex-end">
              <v-btn color="main" class="white--text px-8 mt-8 mb-4 mr-4" small @click.prevent="goBack('lunchTime')">Back</v-btn>
              <v-btn color="main" class="white--text px-8 mt-8 mb-4" small @click.prevent="saveLunchTimeSettings">Update</v-btn>
            </div>
          </div>
        </v-row>
        <!-- update security type options-->
        <v-row v-if="showSecurityTypes" class="container">
          <div style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex">
              <div v-for="(option, index) in securityTypeProperties" :key="index" class="mr-4">
                <v-checkbox v-model="selectedSecurityTypesOptions" :label="translations[option.label]" :value="option"></v-checkbox>
              </div>
            </div>
            <div style="display: flex; justify-content: flex-end">
              <v-btn color="main" class="white--text px-8 mt-8 mb-4 mr-4" small @click.prevent="goBack('securityTypes')">Back</v-btn>
              <v-btn color="main" class="white--text px-8 mt-8 mb-4" small @click.prevent="saveSecurityTypeSettings">Update</v-btn>
            </div>
          </div>
        </v-row>

        <v-row>
          <v-btn color="main" class="white--text px-8 mt-8 mb-4" @click.prevent="saveSettings">Save</v-btn>
        </v-row>
      </v-col>
      <!-- column showing all 22 questions-->
      <v-col class="questions-container font-weight-bold" style="cursor: pointer" :cols="4">
        <v-card>
          <p draggable="true" @dragstart="onDragStart('1')">{{ translations.van }} - 1</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('2')">{{ translations.refuel }} - 2</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('3')">{{ translations.milling }} - 3</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('4')">{{ translations.lunchBreak }} - 4</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('5')">{{ translations.dpiVerification }} - 5</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('6')">{{ translations.materialUsed }} - 6</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('7')">{{ translations.asphaltTemperature }} - 7</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('8')">{{ translations.environmentTemperature }} - 8</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('9')">{{ translations.manHolesVerification }} - 9</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('10')">{{ translations.usedEquipmentInShift }} - 10</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('11')">{{ translations.security }} - 11</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('12')">{{ translations.sameDestinationQuestion }} - 12</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('13')">{{ translations.equipment }} - 13</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('14')">{{ translations.noteQuestion }} - 14</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('15')">{{ translations.boots }} - 15</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('16')">{{ translations.demolitionMan }} - 16</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('17')">{{ translations.confinedSpaces }} - 17</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('18')">{{ translations.deepExcavations }} - 18</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('19')">{{ translations.driving }} - 19</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('20')">{{ translations.preparation }} - 20</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('21')">{{ translations.equipmentQuestion }} - 21</p>
        </v-card>
        <v-card>
          <p draggable="true" @dragstart="onDragStart('22')">{{ translations.didDpi }} - 22</p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { translations } from '../../utils/common'
import PropertyService from '@/service/property/PropertyService'
import QuestionsItem from '@/views/Configurations/QuestionsItem.vue'

export default {
  components: {
    QuestionsItem
  },

  props: ['questionsProperties', 'lunchTimeProperty', 'securityTypesProperty'],

  data() {
    return {
      questions: {},
      lunchTime: [],
      securityTypes: [],
      lunchTimeProperties: [
        {
          'value': 0,
          'label': 'lunchTime0'
        },
        {
          'value': 30,
          'label': 'lunchTime30'
        },
        {
          'value': 60,
          'label': 'lunchTime60'
        },
        {
          'value': 90,
          'label': 'lunchTime90'
        },
        {
          'value': 120,
          'label': 'lunchTime120'
        }
      ],
      securityTypeProperties: [
        {
          'value': 0,
          'label': 'securityType0',
          'to': 'cantiere@tom.it'
        },
        {
          'value': 1,
          'label': 'securityType1',
          'to': 'attrezzature@tom.it'
        },
        {
          'value': 2,
          'label': 'securityType2',
          'to': 'dpi@tom.it'
        },
        {
          'value': 3,
          'label': 'securityType3',
          'to': 'infortunio@tom.it'
        }
      ],
      showLunchTimeOptions: false,
      showSecurityTypes: false,
      selectedLunchTimeOptions: [],
      selectedSecurityTypesOptions: []
    }
  },

  computed: {
    translations() {
      return translations
    }
  },

  mounted() {
    this.questions = this.questionsProperties
    this.lunchTime = this.lunchTimeProperty
    this.securityTypes = this.securityTypesProperty
    this.lunchTime.forEach(time => {
      const selectedOption = this.lunchTimeProperties.find(option => option.label === time.label)
      if (selectedOption) {
        this.selectedLunchTimeOptions.push(selectedOption)
      }
    })
    this.securityTypes.forEach(security => {
      const selectedOption = this.securityTypeProperties.find(option => option.label === security.label)
      if (selectedOption) {
        this.selectedSecurityTypesOptions.push(selectedOption)
      }
    })
  },

  methods: {
    onDragStart(param) {
      event.dataTransfer.setData('text/plain', param)
    },

    onDrop(event) {
      const draggedItem = parseInt(event.dataTransfer.getData('text/plain'))

      // in keySection is the selected section: 'operator', 'driver', 'transfer'...
      const keySection = event.target.closest('.droppable-section').dataset.section
      let keyArray
      if (keySection.startsWith('transfer')) {
        if (keySection === 'transfer-project') {
          keyArray = this.questions['transfer']['project']
        } else if (keySection === 'transfer-endDay') {
          keyArray = this.questions['transfer']['end-day']
        }
      } else {
        keyArray = this.questions[keySection]
      }
      if (keyArray && !keyArray.includes(draggedItem)) {
        keyArray.push(draggedItem)
      }

    },

    deleteQuestion(value, key) {
      let keyArray

      if (key.startsWith('transfer')) {
        if (key === 'transfer-project') {
          keyArray = this.questions['transfer']['project']
        } else if (key === 'transfer-endDay') {
          keyArray = this.questions['transfer']['end-day']
        }
      } else {
        keyArray = this.questions[key]
      }
      if (keyArray) {
        const index = keyArray.indexOf(value)
        if (index >= 0) {
          keyArray.splice(index, 1)
        }
      }
    },

    async saveSettings() {
      const propertyDTO = { key: 'questionsJSON', value: JSON.stringify(this.questions), type: 'JSON' }
      await PropertyService.save({
          propertyDTO: propertyDTO
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
    },

    toggleLunchTime() {
      this.showLunchTimeOptions = true
      this.showSecurityTypes = false
    },

    toggleSecurityType() {
      this.showSecurityTypes = true
      this.showLunchTimeOptions = false
    },

    goBack(value) {
      if (value === 'lunchTime') {
        this.showLunchTimeOptions = false
      } else if (value === 'securityTypes') {
        this.showSecurityTypes = false
      }
    },

    async saveLunchTimeSettings() {
      this.selectedLunchTimeOptions.sort((a, b) => a.value - b.value)
      const propertyDTO = { key: 'lunchTimeJSON', value: JSON.stringify(this.selectedLunchTimeOptions), type: 'JSON' }
      await PropertyService.save({
          propertyDTO: propertyDTO
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
      this.showLunchTimeOptions = false
    },

    async saveSecurityTypeSettings() {
      this.selectedSecurityTypesOptions.sort((a, b) => a.value - b.value)
      const propertyDTO = {
        key: 'securityTypes',
        value: JSON.stringify(this.selectedSecurityTypesOptions),
        type: 'JSON'
      }
      await PropertyService.save({
          propertyDTO: propertyDTO
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
      this.showSecurityTypes = false
    }
  }
}
</script>


<style scoped>
.cols-container {
  display: flex;
  height: 100%;
}

.key-value-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: flex-start;
}

.questions-container {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  text-align: center;
}

.container {
  display: flex;
  align-items: start;
}

.key-container {
  padding: 0;
}

.value-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  padding: 0;
  justify-content: start;
  height: 100%;
}

.value-col-1 {
  display: flex;
  padding: 0;
}

p {
  margin-bottom: 0;
}

.droppable-section {
  display: flex;
  flex-direction: column;
  width: 100%
}
</style>
