<template>
  <div>
    <smart-table ref="smartTable"
                 :headers="headers()"
                 :search-function="searchFunction"
                 preference-key="Account">

      <!-- smart table slot HEADER-ITEM-1: new dialog (activate in the headers) -->
      <template v-slot:HEADER-ITEM-1 v-if="currentUserCanManageCRUD || currentUserCanManageAccount">
        <v-btn @click="openDialog()" color="main" class="white--text" small depressed>
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ translations.newButton }}
        </v-btn>
      </template>

      <!-- smart table slot results -->
      <template v-slot:results="{ item: item }">
        <!-- name -->
        <td>
          {{ item.name }}

          <!-- it's me -->
          <v-tooltip v-if="currentUserId === item.accountId" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" color="green" class="ma-2">mdi-account-check</v-icon>
            </template>

            {{ translations.itsme }}
          </v-tooltip>
        </td>

        <!-- email -->
        <td>
          {{ item.email }}
        </td>

        <!-- employmentStartDate -->
        <td>
          {{ item.employmentStartDate ? momentDate(item.employmentStartDate) : item.employmentStartDate }}
        </td>

        <!-- employmentEndDate -->
        <td>
          <span :style="item.employmentEndDate < todaysDate ? 'color:red' : ''">
            {{ item.employmentEndDate ? momentDate(item.employmentEndDate) : item.employmentEndDate }}
          </span>
        </td>

        <!-- keyCode -->
        <td>
          {{ item.keyCode }}
        </td>

        <!-- qualification -->
        <td>
          {{ item.qualification }}
        </td>

        <!-- profile -->
        <td class="text-lowercase">
          <span v-if="item.employeeId" class="mr-1">
            <v-chip v-if="item.isActive" small color="light-blue" class="white--text">{{ translations.employee }}</v-chip>

            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-on="on" v-bind="attrs" small color="red" class="white--text">{{ translations.employee }}</v-chip>
              </template>

              {{ translations.employeeNotActiveClockInExplain }}
            </v-tooltip>
          </span>

          <v-chip v-if="item.capabilityCount" small color="orange" class="white--text mr-1"> {{ translations.userBackOffice }} {{ item.isAdmin === 0 ? '(' + item.capabilityCount + ')' : '(' + (item.capabilityCount - 1) + ')' }}</v-chip>

          <v-chip v-if="item.isAdmin" small color="main" class="white--text">{{ translations.admin }}</v-chip>
        </td>

        <!-- actions -->
        <td width="100">
          <div class="d-flex justify-space-around">
            <!-- loading -->
            <v-progress-circular v-if="item.loading" :size="15" color="main" indeterminate />

            <!-- edit -->
            <v-icon v-if="!item.loading" @click="openDialog(item)" small class="cursor-pointer">
              {{ (currentUserCanManageCRUD || currentUserCanManageAccount) ? 'mdi-square-edit-outline' : 'mdi-open-in-new' }}
            </v-icon>

            <!-- delete -->
            <v-icon v-if="(currentUserCanManageCRUD || currentUserCanManageAccount) && !item.loading" @click="deleteConfirmation(item)" small color="error lighten-1" class="cursor-pointer">
              mdi-delete-outline
            </v-icon>
          </div>
        </td>
      </template>
    </smart-table>

    <!-- dialog -->
    <account-save-edit-dialog :item="selectedItem" :show="showDialog" :update="isUpdate"
                              @closeDialog="closeDialog()"
                              @updated="$refs.smartTable.getTableRows()" />
  </div>
</template>


<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import AccountService from '@/service/account/AccountService'

import SmartTable from '@/components/SmartTable'
import AccountSaveEditDialog from '@/views/Account/AccountSaveEditDialog'

export default {
  name: 'Account',

  components: {
    SmartTable,
    AccountSaveEditDialog
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      isUpdate: false,
      selectedItem: {}
    }
  },

  mixins: [MomentMixin],

  computed: {
    ...mapState({
      todaysDate: state => state.date.todaysDate,
      currentUserId: state => state.auth.userInfo.account?.id,
      currentUserIsAdmin: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Admin'),
      currentUserCanManageCRUD: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Manage CRUD'),
      currentUserCanManageAccount: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Manage account')
    })
  },

  methods: {
    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'name',
        label: translations.username,
        sort: 'name'
      })

      headers.push({
        type: 'TEXT',
        name: 'email',
        label: translations.email,
        sort: 'email'
      })

      headers.push({
        type: 'LABEL',
        name: 'employmentStartDate',
        label: translations.employmentStartDate,
        sort: 'e.employmentStartDate'
      })

      headers.push({
        type: 'LABEL',
        name: 'employmentEndDate',
        label: translations.employmentEndDate,
        sort: 'e.employmentEndDate'
      })

      headers.push({
        type: 'LABEL',
        name: 'keyCode',
        label: translations.code,
        sort: 'e.keyCode'
      })

      headers.push({
        type: 'LABEL',
        name: 'qualification',
        label: translations.qualification,
        sort: 'e.qualification'
      })

      headers.push({
        type: 'LABEL',
        name: 'profile',
        label: translations.capabilities
      })

      // actions
      headers.push({
        type: 'HEADER-ITEM-1'
      })

      return headers
    },

    async searchFunction(params) {
      // add dynamic param
      params.hideAdmin = !this.currentUserIsAdmin

      // enrich model
      return AccountService.search(params).then(async results => {
        for (let i = 0; i < results.content.length; i++) {
          let result = results.content[i]
          results.content[i] = {
            ...result,
            isActive: this.accountIsActive(result),
            className: this.accountMissingConfig(result) ? 'missing-config' : null
          }
        }
        return results
      })
    },

    accountMissingConfig(item) {
      return item.capabilityCount === 0 && item.employeeId == null
    },

    accountIsActive(item) {
      // is active when there are some capability or there is an employee record with a valid date
      return item.capabilityCount > 0 || (item.employeeId != null && (item.employmentEndDate === null || item.employmentEndDate >= this.todaysDate))
    },

    openDialog(item) {
      this.showDialog = true
      this.isUpdate = !!item
      this.selectedItem = item ? item : {}
    },

    closeDialog() {
      this.showDialog = false
      this.selectedItem = {}
      this.isUpdate = false
    },

    deleteConfirmation(item) {
      if (confirm(translations.areYouSure)) {
        this.deleteItem(item)
      }
    },

    async deleteItem(item) {
      item.loading = true
      await AccountService.delete({
          accountId: item.accountId,
          employeeId: item.employeeId
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.deletedSuccess })
          this.$refs.smartTable.getTableRows()
        })
        .catch(() => {
          this.$root.vtoast.showError({ message: translations.deletedFailed })
        })
        .finally(() => {
          item.loading = false
        })
    }
  }
}
</script>


<style>
.missing-config {
  background: rgba(246, 25, 25, 0.70) !important;
  color: white !important;
}

.missing-config:hover {
  background: rgba(246, 25, 25, 0.60) !important;
  color: grey !important;
}
</style>
