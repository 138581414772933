<template>
  <main class="page-overflow ma-5">
    <v-tabs v-model="tabSelected" show-arrows>
      <v-tab v-for="tab in tabs()" :key="tab.route" @click="updateRoute(tab.route)">
        <v-icon class="mr-1">{{ tab.icon }}</v-icon>
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabSelected" class="elevation-2">
      <v-tab-item v-if="canAccessToManageAccounts">
        <Account />
      </v-tab-item>

      <!-- // FIXME @Deprecated -->
      <!--
      <v-tab-item v-if="canAccessToManageAccountProjects">
        <AccountProject />
      </v-tab-item>
      -->

      <v-tab-item v-if="canAccessToManageProjects">
        <Project />
      </v-tab-item>

      <v-tab-item v-if="canAccessToManageCategories">
        <Category />
      </v-tab-item>

      <v-tab-item v-if="canAccessToManageVehicles">
        <Vehicle />
      </v-tab-item>

      <v-tab-item v-if="canAccessToSuppliers">
        <Supplier />
      </v-tab-item>

      <v-tab-item v-if="canAccessToManageSquads">
        <Squad />
      </v-tab-item>

      <v-tab-item v-if="canAccessToManageLocalUnits">
        <LocalUnit />
      </v-tab-item>

      <v-tab-item v-if="canAccessToManufacturers">
        <Manufacturer />
      </v-tab-item>

      <v-tab-item v-if="canAccessToAdmin">
        <Configuration />
      </v-tab-item>

      <v-tab-item v-if="canAccessToAdmin">
        <Property />
      </v-tab-item>

      <v-tab-item v-if="canAccessToAdmin">
        <Recalculate />
      </v-tab-item>
    </v-tabs-items>
  </main>
</template>


<script>
import { translations } from '@/utils/common'
import { mapState } from 'vuex'

import Account from '@/views/Account/Account'
import Project from '@/views/Project/Project'
import Category from '@/views/Category/Category'
import Vehicle from '@/views/Vehicle/Vehicle'
import Supplier from '@/views/Supplier/Supplier'
import Squad from '@/views/Squad/Squad'
import LocalUnit from '@/views/LocalUnit/LocalUnit'
import Manufacturer from '@/views/Manufacturer/Manufacturer'
import Configuration from '@/views/Configurations/Configuration'
import Property from '@/views/Property/Property'
import Recalculate from '@/views/Recalculate/Recalculate'

export default {
  name: 'Registry',

  components: {
    Account,
    Project,
    Category,
    Vehicle,
    Supplier,
    Squad,
    LocalUnit,
    Manufacturer,
    Configuration,
    Property,
    Recalculate
  },

  data() {
    return {
      translations: translations,

      tabSelected: 0
    }
  },

  mounted() {
    const tabs = this.tabs()
    const index = tabs.findIndex(tab => tab.route === this.$route.params.tab)
    if (index === -1) {
      this.updateRoute(tabs[0].route)
    } else {
      this.tabSelected = index
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    }),

    canAccessToManageAccounts() {
      return this.canAccessTo(['Activity Planner', 'Back Office', 'Long Term Planner', 'Manage news', 'Manage CRUD', 'Manage account'])
    },

    // FIXME @Deprecated
    /*
    canAccessToManageAccountProjects() {
      return this.canAccessTo(['Manage account'])
    },
    */

    canAccessToManageProjects() {
      return this.canAccessTo(['Activity Planner', 'Back Office', 'Long Term Planner', 'Manage CRUD'])
    },

    canAccessToManageCategories() {
      return this.canAccessTo(['Activity Planner', 'Back Office'])
    },

    canAccessToManageVehicles() {
      return this.canAccessTo(['Activity Planner', 'Back Office', 'Manage CRUD'])
    },

    canAccessToSuppliers() {
      return this.canAccessTo(['Activity Planner'])
    },

    canAccessToManageSquads() {
      return this.canAccessTo(['Long Term Planner'])
    },

    canAccessToManageLocalUnits() {
      return this.canAccessTo(['Manage piles'])
    },

    canAccessToManufacturers() {
      return this.canAccessTo(['Manage piles'])
    },

    canAccessToAdmin() {
      return this.canAccessTo(['Admin'])
    }
  },

  methods: {
    canAccessTo(capabilities) {
      return this.userInfo?.account?.capabilities?.some(c => capabilities.includes(c.name))
    },

    tabs() {
      const tabs = []

      if (this.canAccessToManageAccounts) {
        tabs.push({
          route: 'accounts',
          title: translations.manageAccounts,
          icon: 'mdi-account-cog'
        })
      }

      // FIXME @Deprecated
      /*
      if (this.canAccessToManageAccountProjects) {
        tabs.push({
          route: 'accountProject',
          title: translations.accountProject,
          icon: 'mdi-briefcase-account-outline'
        })
      }
      */

      if (this.canAccessToManageProjects) {
        tabs.push({
          route: 'projects',
          title: translations.manageProjects,
          icon: 'mdi-briefcase-outline'
        })
      }

      if (this.canAccessToManageCategories) {
        tabs.push({
          route: 'categories',
          title: translations.manageCategories,
          icon: 'mdi-office-building-cog'
        })
      }

      if (this.canAccessToManageVehicles) {
        tabs.push({
          route: 'vehicles',
          title: translations.manageVehicles,
          icon: 'mdi-car-cog'
        })
      }

      if (this.canAccessToSuppliers) {
        tabs.push({
          route: 'suppliers',
          title: translations.suppliers,
          icon: 'mdi-account-arrow-right'
        })
      }

      if (this.canAccessToManageSquads) {
        tabs.push({
          route: 'squads',
          title: translations.manageSquads,
          icon: 'mdi-account-group'
        })
      }

      if (this.canAccessToManageLocalUnits) {
        tabs.push({
          route: 'localUnits',
          title: translations.manageLocalUnits,
          icon: 'mdi-home-city'
        })
      }

      if (this.canAccessToManufacturers) {
        tabs.push({
          route: 'manufacturer',
          title: translations.manageManufacturers,
          icon: 'mdi-dump-truck'
        })
      }

      if (this.canAccessToAdmin) {
        tabs.push({
          route: 'configurations',
          title: translations.manageConfigurations,
          icon: 'mdi-abacus'
        })

        tabs.push({
          route: 'property',
          title: translations.manageProperties,
          icon: 'mdi-abacus'
        })

        tabs.push({
          route: 'recalculate',
          title: translations.manageRecalculate,
          icon: 'mdi-cog'
        })
      }

      return tabs
    },

    updateRoute(route) {
      this.$router.push('/registry/' + route)
    }
  }
}
</script>


<style>
.v-tabs > .v-tabs-bar {
  background: none !important;
}
</style>
