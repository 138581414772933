<template>
  <multi-select :list-one-title="translations.available"
                :list-one="projectsAvailableFiltered"
                :list-two-title="translations.assigned"
                :list-two="projectsAssigned"
                @updateList="updateList">
  </multi-select>
</template>


<script>
import { translations } from '@/utils/common'

import backOfficeProjectsQuery from '@/service/project/BackOfficeProjectsQuery.gql'

import AccountProjectService from '@/service/accountProject/AccountProjectService'

import MultiSelect from '@/components/MultiSelect'

export default {
  name: 'AccountProjectSection',

  components: {
    MultiSelect
  },

  apollo: {
    backOfficeProjects: {
      query: backOfficeProjectsQuery,
      fetchPolicy: 'network-only',
      result({ data }) {
        this.projectsAvailable = this.convertProjectList(data.backOfficeProjects)
      }
    }
  },

  props: {
    accountId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      loading: false,

      projectsAvailable: [],
      projectsAssigned: []
    }
  },

  created() {
    this.setItem()
  },

  computed: {
    projectsAvailableFiltered() {
      if (this.projectsAssigned) {
        const projectsAssignedId = new Set(this.projectsAssigned.map(project => project.id))
        return this.projectsAvailable.filter(project => !projectsAssignedId.has(project.id))
      }
      return this.projectsAvailable
    }
  },

  methods: {
    convertProjectList(projects) {
      return projects.map(project => ({
        id: project.id,
        name: `${project.keyCode} - ${project.label}`
      }))
    },

    async setItem() {
      if (this.accountId) {
        let projects = await AccountProjectService.projectsByAccountId({ accountId: this.accountId })
        this.projectsAssigned = this.convertProjectList(projects)
      } else {
        this.loading = false
        this.projectsAvailable = []
        this.projectsAssigned = []
      }
    },

    updateList({ updatedList }) {
      this.projectsAssigned = updatedList
      this.confirm()
    },

    async confirm() {
      this.loading = true

      // prepare the object
      await AccountProjectService.save({
          accountProjectSaveOrUpdateDTO: {
            accountId: this.accountId,
            projectIds: this.projectsAssigned.map(({ id }) => id)
          }
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
        })
        .catch(() => {
          this.$root.vtoast.showError({ message: translations.saveError })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
