<template>
  <div class="recalculate-content d-flex justify-space-around">

    <!-- Menu to create new squad -->
    <div style="width: 100%" class="pa-4">
      <div class="d-flex justify-space-between mb-4">
        <div style="width: 300px">
          <v-text-field v-model="squadFilterName" hide-details dense :placeholder="translations.filterBySquadName"></v-text-field>
        </div>

        <v-menu v-model="newSquadMenu" offset-y bottom :close-on-click="false" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="main" class="white--text" small v-bind="attrs" v-on="on">{{ translations.newSquad }}</v-btn>
          </template>
          <v-card width="350px" class="pa-3">
            <p class="ma-0 my-2">{{ translations.whatNameForSquad }}</p>

            <v-text-field v-model="newSquadName" solo hide-details dense height="35px"></v-text-field>

            <div class="d-flex justify-end mt-2">
              <v-btn @click="createNewSquadHandler" color="main" class="white--text" small :disabled="disableCreateBtn">{{ translations.createNewSquad }}</v-btn>
            </div>
          </v-card>
        </v-menu>
      </div>

      <v-row no-gutters class="mb-4">
        <v-col v-for="squad in squadList" :key="squad.id" cols="auto">
          <squad-card
            :squad="squad"
            @delete="deleteSquadHandler(squad.id)"
            @drop-on-squad="handleDrop(squad.id)"
            @remove-employee="employeeId => addOrRemoveEmployee(employeeId, squad.id, 'remove')"
            @update="newName => updateSquadHandler(newName, squad.id)">
          </squad-card>
        </v-col>
      </v-row>

      <div v-if="hasNextSquads" class="d-flex justify-center">
        <v-progress-circular v-if="hasNextSquads" v-intersect.quiet="onIntersect" indeterminate color="main" :size="28"></v-progress-circular>
      </div>
    </div>


    <!-- floating employees -->
    <v-menu offset-y top nudge-left :close-on-click="false" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="main ma-2" dark v-bind="attrs" v-on="on" fab small style="position: fixed; bottom: 30px; right: 30px">
          <v-icon>mdi-account-search</v-icon>
        </v-btn>
      </template>

      <v-card class="card-lists-tabs">
        <v-card v-if="$apollo.queries.availableEmployeesByEndDate.loading">
          <v-card-title class="mb-0 pb-0">{{ translations.employeesPlural }}</v-card-title>
          <div style="margin: 0 auto; width: 93%">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </div>
        </v-card>

        <list v-else :data="filterEmployees" type="employee-all"
              @search="val => (employeeSearch = val)"
              @drag-item="data => dragItemFromList(data, 'employee')"
              class="list-style scroll-bar">
        </list>
      </v-card>
    </v-menu>
  </div>
</template>


<script>
import debounce from 'lodash/debounce'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import { Rules } from '@/utils/mixins/Rules'

import squadsQuery from '@/graphql/Squads.gql'
import availableEmployeesByEndDateQuery from '@/graphql/AvailableEmployeesByEndDate.gql'
import saveSquadMutation from '@/graphql/SaveSquad.gql'
import deleteSquadMutation from '@/graphql/DeleteSquad.gql'
import addOrRemoveEmployeeFromSquadMutation from '@/graphql/AddOrRemoveEmployeeFromSquad.gql'
import updateSquadMutation from '@/graphql/UpdateSquad.gql'

import List from '@/components/List.vue'
import SquadCard from './SquadCard.vue'

export default {
  name: 'Squad',

  components: {
    SquadCard,
    List
  },

  apollo: {
    availableEmployeesByEndDate: {
      query: availableEmployeesByEndDateQuery,
      fetchPolicy: 'network-only'
    }
  },

  data() {
    return {
      translations: translations,

      tab: 0,
      squadList: [],
      employeeSearch: '',
      newSquadName: '',
      newSquadMenu: false,
      draggingEmployee: {},
      page: 1,
      hasNextSquads: true,
      squadFilterName: ''
    }
  },

  created() {
    this.loadQuerySquads()
  },

  mixins: [
    MomentMixin,
    Rules
  ],

  computed: {
    filterEmployees() {
      if (!this.availableEmployeesByEndDate) return []
      return this.availableEmployeesByEndDate.filter(emp => emp.name.toLowerCase().indexOf(this.employeeSearch.toLowerCase()) > -1)
    },

    disableCreateBtn() {
      return !(this.validRequired(this.newSquadName))
    }
  },

  methods: {
    async loadQuerySquads() {
      try {
        const { data } = await this.$apollo.query({
          query: squadsQuery,
          variables: {
            page: 1,
            pageSize: 9,
            sort: 'name',
            order: 'asc',
            name: this.squadFilterName
          },
          fetchPolicy: 'network-only'
        })

        this.hasNextSquads = data.squads.hasNext
        this.squadList.push(...data.squads.content)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchMoreSquads()
      }
    },

    async fetchMoreSquads() {
      this.page++

      const { data } = await this.$apollo.query({
        query: squadsQuery,
        variables: {
          page: this.page,
          pageSize: 9,
          sort: 'name',
          order: 'asc',
          name: this.squadFilterName
        },
        fetchPolicy: 'network-only'
      })

      this.hasNextSquads = data.squads.hasNext
      this.squadList.push(...data.squads.content)
    },

    // This function enables when the item is being dragged
    dragItemFromList(item) {
      this.draggingEmployee = Object.assign({}, item)
    },

    handleDrop(squadId) {
      const employeeId = this.draggingEmployee.id

      this.addOrRemoveEmployee(employeeId, squadId, 'add')
    },

    async createNewSquadHandler() {
      try {
        const squadDTO = {
          name: this.newSquadName,
          id: null,
          employees: null
        }

        const data = await this.$apollo
          .mutate({
            mutation: saveSquadMutation,
            variables: { squadDTO }
          })
          .catch(error => {
            console.log(error)
          })

        this.newSquadMenu = false
        this.newSquadName = ''

        const { name, id } = data.data.saveSquad

        const newSquad = {
          name,
          id,
          employees: []
        }
        this.squadList.push(newSquad)
      } catch (e) {
        console.log(e)
      }
    },

    async deleteSquadHandler(id) {
      try {
        await this.$apollo.mutate({
          mutation: deleteSquadMutation,
          variables: { squadId: id }
        })

        this.squadList = this.squadList.filter(squad => squad.id !== id)
      } catch (error) {
        console.log(error)
      }
    },

    async addOrRemoveEmployee(employeeId, squadId, action) {
      try {
        const { data } = await this.$apollo
          .mutate({
            mutation: addOrRemoveEmployeeFromSquadMutation,
            variables: {
              employeeId: employeeId,
              squadId: squadId
            }
          })
          .catch(error => {
            console.log(error)
          })

        if (!data.addOrRemoveEmployeeFromSquad) return

        const squad = this.squadList.find(squad => squad.id === squadId)
        if (action === 'add') {
          squad.employees.push(this.draggingEmployee)
        } else {
          squad.employees = squad.employees.filter(employee => employee.id !== employeeId)
        }

        this.draggingEmployee = {}
      } catch (e) {
        console.log(e)
      }
    },

    async updateSquadHandler(newName, squadId) {
      try {
        const squadDTO = {
          name: newName,
          id: squadId
        }

        const { data } = await this.$apollo
          .mutate({
            mutation: updateSquadMutation,
            variables: { squadDTO }
          })
          .catch(error => {
            console.log(error)
          })

        if (!data.updateSquad) return

        const squad = this.squadList.find(squad => squad.id === squadId)
        squad.name = newName
      } catch (e) {
        console.log(e)
      }
    },

    getFilteredQuads: debounce(function() {
      this.squadList = []
      this.page = 1
      this.loadQuerySquads()
    }, 650)
  },

  watch: {
    squadFilterName() {
      this.getFilteredQuads()
    }
  }
}
</script>


<style scoped>
.list-style {
  font-size: smaller;
  height: 400px;
  overflow: auto;
}

.card-lists-tabs {
  position: sticky;
  top: 0;
  border-top: 4px solid #104272;
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}
</style>
