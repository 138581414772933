import apolloUtil from '@/service/ApolloUtil'

import localUnitsListQuery from '@/service/localUnit/LocalUnitsListQuery.gql'
import saveLocalUnitMutation from '@/service/localUnit/SaveLocalUnitMutation.gql'
import deleteLocalUnitMutation from '@/service/localUnit/DeleteLocalUnitMutation.gql'

class LocalUnitService {

  search(params) {
    return apolloUtil.query(localUnitsListQuery, params)
      .then((response) => response?.data?.localUnitsList)
  }

  save(params) {
    return apolloUtil.mutate(saveLocalUnitMutation, params)
      .then((response) => response?.data?.saveLocalUnit)
  }

  delete(params) {
    return apolloUtil.mutate(deleteLocalUnitMutation, params)
  }

}

export default new LocalUnitService()
