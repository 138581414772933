<template>
  <v-row class="ma-0" justify="center">
    <v-dialog v-model="dialog"
              @click:outside="$emit('closeDialog')"
              @keydown="value => value.key === 'Escape' ? $emit('closeDialog') : ''"
              scrollable max-width="700px">

      <v-card class="pa-1">
        <!-- card title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ isUpdate ? `${translations.edit} ${this.item.label}` : translations.newCategory }}</h3>
        </v-card-title>

        <v-card-text>
          <!-- section category -->
          <div class="section-divider mt-4">
            <div class="section-divider-title">{{ translations.category }}</div>

            <!-- keyCode -->
            <v-col class="pa-0 mt-1">
              <b class="ml-8">{{ translations.code }} *</b>
              <smart-text-field v-model="selectedItem.keyCode" prepend-icon="mdi-roman-numeral-3"
                                :rules="[requiredRule]"
                                :already-exists-function="checkKeyCode"
                                @has-error="value => hasError.keyCode = value">
              </smart-text-field>
            </v-col>

            <!-- label -->
            <v-col class="pa-0 mt-4">
              <b class="ml-8">{{ translations.name }} *</b>
              <smart-text-field v-model="selectedItem.label" prepend-icon="mdi-roman-numeral-3"
                                :rules="[requiredRule]"
                                :already-exists-function="checkLabel"
                                @has-error="value => hasError.label = value">
              </smart-text-field>
            </v-col>

            <!-- active -->
            <v-col class="pa-0 mt-2" cols="auto">
              <b class="ml-8">{{ translations.active }}</b>
              <div style="max-width: 70px">
                <v-switch v-model="selectedItem.active" prepend-icon="mdi-check-circle-outline"
                          class="mt-1" dense>
                </v-switch>
              </div>
            </v-col>
          </div>
        </v-card-text>

        <!-- card actions -->
        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="space-around">
            <v-btn @click="$emit('closeDialog')" class="px-8" outlined :loading="loading">{{ translations.cancel }}</v-btn>

            <v-btn @click="confirm" :disabled="disableBtn" :loading="loading"
                   color="main" class="white--text px-8">
              {{ translations.confirm }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.saveError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>


<script>
import { translations } from '@/utils/common'
import { Rules } from '@/utils/mixins/Rules'

import ProjectService from '@/service/project/ProjectService'

import SmartTextField from '@/components/SmartTextField'
import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'CategorySaveEditDialog',

  components: {
    SmartTextField,
    MessageDialog,
    WarningSvg
  },

  props: {
    item: {
      type: Object,
      default: () => {
      }
    },

    show: {
      type: Boolean,
      default: false,
      required: true
    },

    update: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      dialog: false,
      loading: false,
      isUpdate: false,
      showErrorMsgDialog: false,
      hasError: {
        keyCode: false,
        label: false
      },

      selectedItem: {}
    }
  },

  created() {
    this.setItem()
  },

  computed: {
    disableBtn() {
      return !(
        (!this.hasError.keyCode && this.validRequired(this.selectedItem.keyCode, 40))
        && (!this.hasError.label && this.validRequired(this.selectedItem.label, 40))
      )
    }
  },

  mixins: [
    Rules
  ],

  methods: {
    setItem() {
      if (this.item.id) {
        const { id, active, label, keyCode } = this.item
        this.selectedItem = { id, active, label, keyCode }
        delete this.selectedItem['__typename']
      } else {
        this.selectedItem = { active: true }
      }
    },

    async confirm() {
      this.loading = true
      await ProjectService.saveCategoryProject({
          categoryRequestObject: this.selectedItem
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
          this.$emit('closeDialog')
        })
    },

    checkLabel(label) {
      return ProjectService.checkLabel({ label: label })
    },

    checkKeyCode(keyCode) {
      return ProjectService.checkKeyCode({ keyCode: keyCode })
    }
  },

  watch: {
    show() {
      this.dialog = this.show
    },

    update() {
      this.isUpdate = this.update
    },

    item() {
      this.setItem()
    }
  }
}
</script>
