<template>
  <v-text-field v-bind="$props" v-on="$listeners"
                @input="duplicateCheckInputHandler" :error-messages="errors"
                class="mt-1" outlined dense>
  </v-text-field>
</template>


<script>
import { VTextField } from 'vuetify/lib'
import { translations } from '@/utils/common'
import _ from 'lodash'

export default {
  name: 'SmartTextField',
  extends: VTextField,

  props: {
    alreadyExistsFunction: {
      required: true,
      type: Function
    }
  },

  data() {
    return {
      originalValue: null,
      errors: [],
      debouncedCheck: _.debounce(function(value) {
        this.checkFieldForDuplicates(value)
      }, 250)
    }
  },

  created() {
    this.originalValue = this.value
  },

  methods: {
    duplicateCheckInputHandler(value) {
      this.debouncedCheck.cancel()
      this.debouncedCheck(value.trim())
    },

    async checkFieldForDuplicates(value) {
      if (this.originalValue === value || value.length === 0) {
        this.errors = []
      } else {
        const exists = await this.alreadyExistsFunction(value)
        this.errors = exists ? [translations.validationAlreadyExists] : []
      }

      this.$emit('has-error', this.errors.length > 0)
    }
  }
}
</script>
