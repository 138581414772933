<template>
  <div>
    <smart-table ref="smartTable"
                 :headers="headers()"
                 :search-function="searchFunction"
                 preference-key="Property">

      <!-- smart table slot HEADER-ITEM-1: new dialog (activate in the headers) -->
      <template v-slot:HEADER-ITEM-1>
        <v-btn @click="openDialog()" color="main" class="white--text" small depressed>
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ translations.newButton }}
        </v-btn>
      </template>

      <!-- smart table slot results -->
      <template v-slot:results="{ item: item }">
        <!-- key -->
        <td style="width:200px">
          {{ item.key }}
        </td>

        <!-- type -->
        <td style="width:150px">
          {{ item.type }}
        </td>

        <!-- value -->
        <td style="max-width:800px">
          {{ item.value }}
        </td>

        <!-- actions -->
        <td style="width:100px">
          <div class="d-flex justify-space-around">
            <!-- loading -->
            <v-progress-circular v-if="item.loading" :size="15" color="main" indeterminate />

            <!-- edit -->
            <v-icon v-if="!item.loading" @click="openDialog(item)" small class="cursor-pointer">
              mdi-square-edit-outline
            </v-icon>

            <!-- delete -->
            <v-icon v-if="!item.loading" @click="deleteConfirmation(item)" small color="error lighten-1" class="cursor-pointer">
              mdi-delete-outline
            </v-icon>
          </div>
        </td>
      </template>
    </smart-table>

    <!-- dialog -->
    <property-save-edit-dialog :item="selectedItem" :show="showDialog" :update="isUpdate"
                               @closeDialog="closeDialog()"
                               @updated="$refs.smartTable.getTableRows()" />
  </div>
</template>


<script>
import { translations } from '@/utils/common'

import PropertyService from '@/service/property/PropertyService'

import SmartTable from '@/components/SmartTable'
import PropertySaveEditDialog from '@/views/Property/PropertySaveEditDialog'

export default {
  name: 'Property',

  components: {
    SmartTable,
    PropertySaveEditDialog
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      isUpdate: false,
      selectedItem: {}
    }
  },

  methods: {
    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'key',
        label: translations.key,
        sort: 'key'
      })

      headers.push({
        type: 'LABEL',
        name: 'type',
        label: 'Type',
        sort: 'type'
      })

      headers.push({
        type: 'TEXT',
        name: 'value',
        label: translations.value,
        sort: 'value'
      })

      // actions
      headers.push({
        type: 'HEADER-ITEM-1'
      })

      return headers
    },

    searchFunction(params) {
      return PropertyService.search(params)
    },

    openDialog(item) {
      this.showDialog = true
      this.isUpdate = !!item
      this.selectedItem = item ? item : {}
    },

    closeDialog() {
      this.showDialog = false
      this.selectedItem = {}
      this.isUpdate = false
    },

    deleteConfirmation(item) {
      if (confirm(translations.areYouSure)) {
        this.deleteItem(item)
      }
    },

    async deleteItem(item) {
      item.loading = true
      await PropertyService.delete({
          key: item.key
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.deletedSuccess })
          this.$refs.smartTable.getTableRows()
        })
        .catch(() => {
          this.$root.vtoast.showError({ message: translations.deletedFailed })
        })
        .finally(() => {
          item.loading = false
        })
    }
  }
}
</script>
