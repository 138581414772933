<template>
  <v-container>
    <h2 class="mb-4">{{ translations.nextSchedule }}</h2>

    <div v-if="value">
      <div>
        <h4 class="mb-4">{{ translations.nextScheduleElements }}</h4>
        <div v-for="(option, index) in elementsAvailable" :key="index">
          <v-checkbox v-model="value.elements" :label="translations['nextScheduleOption_' + option]" :value="option" class="ma-0 pa-0"></v-checkbox>
        </div>
      </div>

      <div class="mt-4">
        <h4>{{ translations.nextScheduleHour }}</h4>
        <v-text-field v-model="value.hour" type="number" hide-details dense />
      </div>

      <div class="mt-8">
        <h4>{{ translations.nextScheduleOccurrences }}</h4>
        <v-text-field v-model="value.occurrences" type="number" hide-details dense />
      </div>

      <div class="mt-8">
        <h4>{{ translations.nextScheduleSolarDays }}</h4>
        <v-switch v-model="value.solarDays" :prepend-icon="value.solarDays ? 'mdi-weather-sunny' : 'mdi-weather-sunny-off'" dense inset />
      </div>

      <div class="mt-4">
        <h4>{{ translations.nextScheduleAlwaysShow }}</h4>
        <v-switch v-model="value.alwaysShow" :prepend-icon="value.alwaysShow ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" dense inset />
      </div>
    </div>

    <v-btn @click.prevent="saveSettings" color="main" class="white--text">Salva</v-btn>
  </v-container>
</template>


<script>
import { translations } from '@/utils/common'

import PropertyService from '@/service/property/PropertyService'

export default {
  props: {
    mobileNextSchedule: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    translations: translations,
    elementsAvailable: ['time', 'where', 'supervisor', 'vehicles', 'notes', 'squad', 'squadVehicles'],
    value: null
  }),

  mounted() {
    this.value = JSON.parse(this.mobileNextSchedule.value)
    delete this.mobileNextSchedule['__typename']
  },

  methods: {
    async saveSettings() {
      this.mobileNextSchedule.value = JSON.stringify(this.value)
      console.log('save', this.mobileNextSchedule)

      await PropertyService.save({ propertyDTO: this.mobileNextSchedule })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.$root.vtoast.showError({ message: translations.generalError })
        })
    }
  }
}
</script>
