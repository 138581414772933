<template>
  <div class="settings-container">
    <v-row>
      <v-col cols="2" style="height: 100%; overflow-y: auto">
        <v-list>
          <v-list-item @click="toggleSetting( 1)" :class="{ 'selected': tab === 1 }">
            <v-list-item-title>{{ translations.nextSchedule }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toggleSetting( 2)" :class="{ 'selected': tab === 2 }">
            <v-list-item-title>Questions Configuration</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toggleSetting( 3)" :class="{ 'selected': tab === 3 }">
            <v-list-item-title>Planner Layout</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toggleSetting( 4)" :class="{ 'selected': tab === 4 }">
            <v-list-item-title>Codes Settings</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toggleSetting( 5)" :class="{ 'selected': tab === 5 }">
            <v-list-item-title>Mobile Driver</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toggleSetting( 6)" :class="{ 'selected': tab === 6 }">
            <v-list-item-title>Mobile Menu</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col cols="10" style="height: 100%; overflow-y: auto">
        <schedule-configurations v-if="tab === 1" :mobileNextSchedule="mobileNextSchedule" />

        <question-configurations v-if="tab === 2"
                                 :questionsProperties="questionsProperties"
                                 :lunchTimeProperty="lunchTimeProperty"
                                 :securityTypesProperty="securityTypesProperty">
        </question-configurations>

        <planner-layout v-if="tab === 3" :plannerLayoutProperties="plannerLayoutProperties" />

        <codes-configurations v-if="tab === 4" :codesProperties="codesProperties" />

        <mobile-driver-configurations v-if="tab === 5" :mobileDriverProperties="mobileDriverProperties" />

        <mobile-menu-configurations v-if="tab === 6" :mobileMenuProperties="mobileMenuProperties" />
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { translations } from '@/utils/common'

import ScheduleConfigurations from '@/views/Configurations/ScheduleConfigurations.vue'
import QuestionConfigurations from '@/views/Configurations/QuestionConfigurations.vue'
import PropertyService from '@/service/property/PropertyService'
import PlannerLayout from '@/views/Configurations/PlannerLayout.vue'
import CodesConfigurations from '@/views/Configurations/CodesConfigurations.vue'
import MobileDriverConfigurations from '@/views/Configurations/MobileDriverConfigurations.vue'
import MobileMenuConfigurations from '@/views/Configurations/MobileMenuConfigurations.vue'

export default {
  components: {
    CodesConfigurations,
    PlannerLayout,
    QuestionConfigurations,
    ScheduleConfigurations,
    MobileDriverConfigurations,
    MobileMenuConfigurations
  },

  data() {
    return {
      tab: 0,

      mobileNextSchedule: [],
      questionsProperties: {},
      lunchTimeProperty: [],
      securityTypesProperty: [],
      plannerLayoutProperties: [],
      codesProperties: [],
      mobileDriverProperties: [],
      mobileMenuProperties: []
    }
  },

  computed: {
    translations: () => translations
  },

  mounted() {
    this.getPropertiesData()
  },

  methods: {
    toggleSetting(index) {
      this.tab = index
    },

    getPropertiesData() {
      this.searchFunction({
          page: 0,
          size: 99
        })
        .then((response) => {
            this.updateResults(response)
          }
        )
        .catch(() => this.updateResults())
    },

    searchFunction(params) {
      return PropertyService.search(params)
    },

    updateResults(data) {
      // filtering for 'next-schedule' properties
      this.mobileNextSchedule = data.content?.filter(prop => prop.key === 'mobileNextSchedule')[0]

      // filtering for 'questionsJSON' property
      this.questionsProperties = JSON.parse(data.content?.filter(prop => prop.key.startsWith('questionsJSON'))[0].value)

      // filtering for 'lunchTimeJSON' property
      this.lunchTimeProperty = JSON.parse(data.content?.filter(prop => prop.key.startsWith('lunchTimeJSON'))[0].value)

      // filtering for 'securityTypes' property
      this.securityTypesProperty = JSON.parse(data.content?.filter(prop => prop.key.startsWith('securityTypes'))[0].value)

      // filtering for planner layout properties
      this.plannerLayoutProperties = data.content?.filter(prop =>
        prop.key === 'closeProgramHeader' || prop.key === 'fixedProjectScheduleHeight' ||
        prop.key === 'plannerResourcesSelectedOnBottom' || prop.key === 'showProjectDescriptionInSchedule' ||
        prop.key === 'useBiggerFontInSchedule')

      // filtering for codes properties
      this.codesProperties = data.content?.filter(prop =>
        prop.key === 'maintenanceKeyCode' || prop.key === 'layOffEmployeeStatusCode' ||
        prop.key === 'millingSubpartKeyCode' || prop.key === 'preparationSubpartKeyCode')

      // filtering for mobile driver properties
      this.mobileDriverProperties = data.content?.filter(prop => prop.key.startsWith('mobileDriver'))

      // filtering for mobile menu properties
      this.mobileMenuProperties = data.content?.filter(prop =>
        prop.key === 'mobileShowDocuments' || prop.key === 'mobileShowPayroll' || prop.key === 'mobileShowNews')

      // set first tab
      this.tab = 1
    }
  }
}
</script>


<style scoped>
.settings-container {
  display: flex;
  height: calc(100vh - 20px - 10px - 50px - 0px - 20px);
  overflow: hidden;
}

.list-container ul {
  margin-top: 15px;
}

.list-container ul li {
  list-style: none;
  margin-bottom: 15px;
  cursor: pointer;
}

.selected {
  background-color: #c0c0c0;
}
</style>
