<template>
  <div class="d-flex justify-space-between">

    <!-- list one -->
    <div class="section-divider mt-1 pb-0 mb-0" style="width: 50%">
      <div class="section-divider-title mb-0">{{ listOneTitle }}</div>

      <div class="list-style">
        <!-- search -->
        <div style="margin: 0 auto; width: 93%;">
          <v-text-field v-model="searchQueryListOne" class="my-2" solo hide-details :placeholder="translations.search"></v-text-field>
        </div>

        <!-- items -->
        <div class="scroll-bar scrollable">
          <draggable class="draggable-list" :list="localListOne" group="list" @add="onAddItem($event, localListOne)" v-bind="dragOptions">
            <div v-for="item in filteredListOne" :key="item.id" :class="['d-flex', 'align-center', 'justify-space-between', 'pa-2', 'hover-style']" @dblclick="handleDoubleClick(item, 'listOne')" style="margin: 5px;">
              <strong class="ma-0 pa-0">{{ item.name }}</strong>
            </div>
            <div v-if="!filteredListOne.length" class="d-flex justify-center align-center" style="height: 100%;">
              <span>{{ translations.noElementsYet }}</span>
            </div>
          </draggable>
        </div>
      </div>
    </div>


    <!-- list two -->
    <div class="section-divider mt-1 pb-0 mb-0" style="width: 50%">
      <div class="section-divider-title mb-0">{{ listTwoTitle }}</div>

      <div class="list-style">
        <!-- search -->
        <div style="margin: 0 auto; width: 93%;">
          <v-text-field v-model="searchQueryListTwo" class="my-2" solo hide-details :placeholder="translations.search"></v-text-field>
        </div>

        <!-- items -->
        <div class="scroll-bar scrollable">
          <draggable class="draggable-list" :list="localListTwo" group="list" @add="onAddItem($event, localListTwo)" v-bind="dragOptions">
            <div v-for="item in filteredListTwo" :key="item.id" :class="['d-flex', 'align-center', 'justify-space-between', 'pa-2', 'hover-style']" @dblclick="handleDoubleClick(item, 'listTwo')" style="margin: 5px;">
              <strong class="ma-0 pa-0">{{ item.name }}</strong>
            </div>
            <div v-if="!filteredListTwo.length" class="d-flex justify-center align-center" style="height: 100%;">
              <span>{{ translations.noElementsYet }}</span>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { translations } from '@/utils/common'
import Draggable from 'vuedraggable'

export default {
  name: 'List',

  components: {
    Draggable
  },

  created() {
    this.localListOne = [...this.listOne]
    this.localListTwo = [...this.listTwo]
  },

  data() {
    return {
      translations: translations,

      searchQueryListOne: '',
      searchQueryListTwo: '',
      localListOne: [],
      localListTwo: []
    }
  },

  props: {
    listOne: {
      type: Array,
      default: () => []
    },

    listTwo: {
      type: Array,
      default: () => []
    },

    listOneTitle: {
      type: String,
      default: ''
    },

    listTwoTitle: {
      type: String,
      default: ''
    }
  },

  computed: {
    filteredListOne() {
      if (!this.searchQueryListOne) return this.localListOne
      return this.localListOne.filter(item => item.name.toLowerCase().includes(this.searchQueryListOne.toLowerCase()))
    },

    filteredListTwo() {
      if (!this.searchQueryListTwo) return this.localListTwo
      return this.localListTwo.filter(item => item.name.toLowerCase().includes(this.searchQueryListTwo.toLowerCase()))
    },

    dragOptions() {
      return {
        animation: 200,
        group: 'htmlElements',
        ghostClass: 'ghost'
      }
    }
  },

  methods: {
    onAddItem(event, list) {
      const addedItem = list.splice(event.newIndex, 1)[0]
      list.unshift(addedItem)

      this.$emit('updateList', { updatedList: this.localListTwo })
    },

    handleDoubleClick(item, fromList) {
      const sourceList = fromList === 'listOne' ? this.localListOne : this.localListTwo
      const targetList = fromList === 'listOne' ? this.localListTwo : this.localListOne

      // Find and remove the item from the source list
      const itemIndex = sourceList.findIndex(i => i.id === item.id)
      if (itemIndex > -1) {
        sourceList.splice(itemIndex, 1)
      }

      // Add the item to the beginning of the target list
      targetList.unshift(item)

      // Update local lists to trigger reactivity
      this.localListOne = fromList === 'listOne' ? sourceList : targetList
      this.localListTwo = fromList === 'listOne' ? targetList : sourceList

      // Emit an event with the updated lists for the parent component to handle
      this.$emit('updateList', { updatedList: this.localListTwo })
    }
  },

  watch: {
    listOne(newVal) {
      this.searchQueryListOne = ''
      this.localListOne = [...newVal]
    },

    listTwo(newVal) {
      this.searchQueryListTwo = ''
      this.localListTwo = [...newVal]
    }
  }
}
</script>

<style scoped>
.list-style {
  margin: 0 -16px;
  font-size: smaller;
}

.scrollable {
  height: 415px;
  overflow: auto;
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
}

.scroll-bar::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
  cursor: grab !important;
}

.hover-style {
  transition: 0.4s;
  cursor: grab;
}

.hover-style:hover {
  background: #d9d9d9;
}
</style>
