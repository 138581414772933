import apolloUtil from '@/service/ApolloUtil'

import supplierListQuery from '@/service/supplier/SupplierListQuery.gql'
import saveSupplierMutation from '@/service/supplier/SaveSupplierMutation.gql'
import deleteSupplierMutation from '@/service/supplier/DeleteSupplierMutation.gql'
import checkSupplierExistByKeyCodeQuery from '@/service/supplier/CheckSupplierExistByKeyCodeQuery.gql'
import checkSupplierExistByDescriptionQuery from '@/service/supplier/CheckSupplierExistByDescriptionQuery.gql'

class SupplierService {

  search(params) {
    return apolloUtil.query(supplierListQuery, params)
      .then((response) => response?.data?.supplierList)
  }

  save(params) {
    return apolloUtil.mutate(saveSupplierMutation, params)
      .then((response) => response?.data?.saveSupplier)
  }

  delete(params) {
    return apolloUtil.mutate(deleteSupplierMutation, params)
  }

  checkSupplierKeyCode(keyCode) {
    return apolloUtil.query(checkSupplierExistByKeyCodeQuery, keyCode)
      .then((response) => response?.data?.supplierKeyCodeExist)
  }

  checkSupplierDescription(description) {
    return apolloUtil.query(checkSupplierExistByDescriptionQuery, description)
      .then((response) => response?.data?.supplierDescriptionExist)
  }

}

export default new SupplierService()
