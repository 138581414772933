<template>
  <v-card min-width="250" height="250" class="ma-10 elevation-10">
    <v-card-title>
      <v-icon color="main" class="mr-1">{{ this.icon }}</v-icon>
      {{ this.title }}
    </v-card-title>

    <v-card-text style="height: 135px">
      {{ this.descr }}

      <template v-if="withDates">
        <v-menu transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-bind="attrs" v-on="on" v-model="dateFromLabel" :placeholder="translations.dateFrom"
                          @click:clear="() => onDateFromChange(null)"
                          readonly clearable hide-details />
          </template>
          <v-date-picker v-model="dateFrom" :max="dateTo" locale="it" @change="value => onDateFromChange(value)" />
        </v-menu>

        <v-menu transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-bind="attrs" v-on="on" v-model="dateToLabel" :placeholder="translations.toDate"
                          @click:clear="() => onDateToChange(null)"
                          readonly clearable hide-details />
          </template>
          <v-date-picker v-model="dateTo" :min="dateFrom" locale="it" @change="value => onDateToChange(value)" />
        </v-menu>
      </template>
    </v-card-text>

    <v-card-actions class="d-flex justify-space-around" style="border-top: 1px solid rgba(125, 125, 125, 0.2)">
      <v-btn :loading="loading" :disabled="withDates && (!dateFrom || !dateTo)"
             @click="updateReports"
             color="main" class="white--text" small>
        Ricalcola
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'

export default {
  name: 'RecalculateCard',

  props: {
    icon: {
      required: true,
      type: String
    },

    title: {
      required: true,
      type: String
    },

    descr: {
      required: true,
      type: String
    },

    searchFunction: {
      required: true,
      type: Function
    },

    withDates: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      translations: translations,

      dateFrom: null,
      dateFromLabel: null,

      dateTo: null,
      dateToLabel: null,

      loading: false
    }
  },

  mixins: [MomentMixin],

  methods: {
    onDateFromChange(date) {
      this.dateFrom = date
      this.dateFromLabel = this.formatFE(date)
    },

    onDateToChange(date) {
      this.dateTo = date
      this.dateToLabel = this.formatFE(date)
    },

    updateReports() {
      this.loading = true

      let params = this.withDates ? {
        dateFromStr: this.dateFrom,
        dateToStr: this.dateTo
      } : {}

      this.searchFunction(
        params
      ).finally(() => {
        this.loading = false
        this.$root.vtoast.show({ message: translations.updated })
      })
    }
  }
}
</script>
