<template>
  <div>
    <smart-table ref="smartTable"
                 :headers="headers()"
                 :search-function="searchFunction"
                 preference-key="Vehicle">

      <!-- smart table slot HEADER-ITEM-1: new dialog (activate in the headers) -->
      <template v-slot:HEADER-ITEM-1 v-if="currentUserCanManageCRUD">
        <v-btn @click="openDialog()" color="main" class="white--text" small depressed>
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ translations.newButton }}
        </v-btn>
      </template>

      <!-- smart table slot results -->
      <template v-slot:results="{ item: item }">
        <!-- keyCode -->
        <td>
          {{ item.keyCode }}
        </td>

        <!-- code -->
        <td>
          {{ item.code }}
        </td>

        <!-- label -->
        <td>
          {{ item.label }}
        </td>

        <!-- startDate -->
        <td>
          {{ item.startDateStr ? momentDate(item.startDateStr) : item.startDateStr }}
        </td>

        <!-- endDate -->
        <td>
          {{ item.endDateStr ? momentDate(item.endDateStr) : item.endDateStr }}
        </td>

        <!-- license -->
        <td>
          {{ item.license }}
        </td>

        <!-- actions -->
        <td width="100">
          <div class="d-flex justify-space-around">
            <!-- loading -->
            <v-progress-circular v-if="item.loading" :size="15" color="main" indeterminate />

            <!-- edit -->
            <v-icon v-if="!item.loading" @click="openDialog(item)" small class="cursor-pointer">
              {{ currentUserCanManageCRUD ? 'mdi-square-edit-outline' : 'mdi-open-in-new' }}
            </v-icon>

            <!-- delete -->
            <v-icon v-if="currentUserCanManageCRUD && !item.loading" @click="deleteConfirmation(item)" small color="error lighten-1" class="cursor-pointer">
              mdi-delete-outline
            </v-icon>
          </div>
        </td>
      </template>
    </smart-table>

    <!-- dialog -->
    <vehicle-save-edit-dialog :item="selectedItem" :show="showDialog" :update="isUpdate"
                              @closeDialog="closeDialog()"
                              @updated="$refs.smartTable.getTableRows()" />
  </div>
</template>


<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import VehicleService from '@/service/vehicle/VehicleService'

import SmartTable from '@/components/SmartTable'
import VehicleSaveEditDialog from '@/views/Vehicle/VehicleSaveEditDialog'

export default {
  name: 'Vehicle',

  components: {
    SmartTable,
    VehicleSaveEditDialog
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      isUpdate: false,
      selectedItem: {}
    }
  },

  mixins: [MomentMixin],

  computed: {
    ...mapState({
      currentUserCanManageCRUD: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Manage CRUD')
    })
  },

  methods: {
    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'keyCode',
        label: translations.keyCode,
        sort: 'keyCode'
      })

      headers.push({
        type: 'TEXT',
        name: 'code',
        label: translations.code,
        sort: 'code'
      })

      headers.push({
        type: 'TEXT',
        name: 'label',
        label: translations.label,
        sort: 'label'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'startDateStr',
        label: translations.startDate,
        sort: 'startDate'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'endDateStr',
        label: translations.endDate,
        sort: 'endDate'
      })

      headers.push({
        type: 'TEXT',
        name: 'license',
        label: translations.license,
        sort: 'license'
      })

      // actions
      headers.push({
        type: 'HEADER-ITEM-1'
      })

      return headers
    },

    searchFunction(params) {
      return VehicleService.search(params)
    },

    openDialog(item) {
      this.showDialog = true
      this.isUpdate = !!item
      this.selectedItem = item ? item : {}
    },

    closeDialog() {
      this.showDialog = false
      this.selectedItem = {}
      this.isUpdate = false
    },

    deleteConfirmation(item) {
      if (confirm(translations.areYouSure)) {
        this.deleteItem(item)
      }
    },

    async deleteItem(item) {
      item.loading = true
      await VehicleService.delete({
          vehicleId: item.id
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.deletedSuccess })
          this.$refs.smartTable.getTableRows()
        })
        .catch(() => {
          this.$root.vtoast.showError({ message: translations.deletedFailed })
        })
        .finally(() => {
          item.loading = false
        })
    }
  }
}
</script>
